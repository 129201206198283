import React, { useState, useRef } from "react"
import { css } from "@emotion/react"
//import PNPLogo from "../assets/logo/pnp_pri_blk_rgb.svg"
import SALogo from "../assets/logos/startupautobahn.svg"
import { color, fontStyle, fontSize } from "../styles/theme"
import { wrapper } from "../styles/elements"
import GeneralData from "../../../data/raw/general"
import Link from "../../link"
import Envelope from "../assets/svg/envelope.svg"
import Enter from "../assets/svg/externallink.svg"
import Heart from "../assets/svg/heart-filled.svg"
import { IconButtonRounded } from "../styles/elements"
import NavData from "../../../data/navigation"
import Config from "../../../../gatsby-config"
import ButtonDropdown from "./navigation-dropdown-button"
import InnerDropdown from "./navigation-dropdown-inner"
import mq from "../styles/mediaquerys"

const LogoEl = ({ darkFooter }) => {
  return (
    <div
      css={css`
        padding: 1em 1em 0;
        ${mq[2]} {
          padding: 1em 0 0;
        }
        svg {
          width: 10em;
          fill: ${darkFooter ? "white" : "black"};
        }
      `}
    >
      <SALogo />
      <p
        css={css`
          font-size: 0.75em;
          font-weight: 400;
          color: ${color.gray400};
          margin-top: 1em;
          display: block;
          max-width: 25em;
        `}
      >
        STARTUP AUTOBAHN powered by Plug and Play is an open innovation platform
        that provides an interface between innovative tech companies and
        industry-leading corporations.
      </p>
    </div>
  )
}

const CopyrightEL = ({ darkFooter }) => {
  return (
    <div
      css={css`
        padding: 1em;
        ${mq[2]} {
          padding: 1em 0 0;
        }
      `}
    >
      <IconButtonRounded
        name={NavData.footer.info.website.title}
        url={NavData.footer.info.website.url}
        icon={<Enter />}
        css={css`
          font-size: ${fontSize.small};
          border: 1px solid ${darkFooter ? color.plue300 : color.plue400};
          color: ${darkFooter ? color.plue300 : color.plue400};
          display: inline-flex;
          align-items: center;
          svg {
            stroke: ${darkFooter ? color.plue300 : color.plue400};
          }
          &:before {
            background: none;
          }
        `}
      />
      <div
        css={css`
          margin-top: 1em;
        `}
      >
        <p css={fontStyle.footer.item}>
          &copy; {NavData.footer.info.copyright}
        </p>
        <p css={fontStyle.footer.item}>
          <Link to={`/imprint`}>Imprint & Legal</Link> |{" "}
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
        </p>
      </div>
    </div>
  )
}

const SocialMediaElements = ({ inline = true, size = 1, darkFooter }) => {
  const socialIcon = css`
    width: ${size}em;
    margin: 0 0.5em;
  `

  return GeneralData?.social
    ? (GeneralData.social.length > 0 || GeneralData.contact?.email) && (
        <ul
          css={css`
            list-style: none;
            margin: 0;
            display: flex;
            margin: ${inline ? "1.5em" : 0} -0.5em 0;
            svg {
              fill: ${darkFooter ? "white" : "black"};
            }
          `}
        >
          {GeneralData.social.map((e, index) => (
            <li key={index} css={socialIcon}>
              <Link to={e.url}>{e.icon}</Link>
            </li>
          ))}
          {GeneralData.contact?.email && (
            <li css={socialIcon}>
              <Link to={`mailto:${GeneralData.contact?.email}`}>
                <Envelope />
              </Link>
            </li>
          )}
        </ul>
      )
    : ""
}

const Contact = ({ className, darkFooter }) => {
  return (
    <div className={className}>
      <p
        css={[
          fontStyle.footer.title,
          css`
            margin-bottom: 0.75em;
            color: ${darkFooter ? "white" : "black"};
          `,
        ]}
      >
        Get in touch
      </p>
      <p css={[fontStyle.footer.item]}>Questions or feedback?</p>
      <p css={fontStyle.footer.item}>We‘d love to hear from you.</p>
      <SocialMediaElements darkFooter={darkFooter} />
    </div>
  )
}

const Dropdown = ({ className, data, darkFooter }) => {
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState(0)
  const dropdownContent = useRef(null)

  const toggleDropdown = () => {
    setActive(current => !current)

    if (active) {
      setHeight(0)
    } else {
      setHeight(dropdownContent.current.scrollHeight)
    }
  }

  if (data.items && data.items.length > 0) {
    return (
      <div
        className={className}
        css={css`
          :not(:last-of-type) {
            margin-bottom: 0.5em;
          }
        `}
      >
        <ButtonDropdown
          darkFooter={darkFooter}
          title={data.header}
          active={active}
          trigger={toggleDropdown}
        />
        <div
          ref={dropdownContent}
          css={css`
            height: ${height + "px"};
            overflow: hidden;
            transition: height ease-in-out 0.3s;
          `}
        >
          <div
            css={css`
              padding: 0.5em 1em 1em;
            `}
          >
            <InnerDropdown data={data.items} forceOpen={true} />
          </div>
        </div>
      </div>
    )
  } else return null
}

const Acknowledgement = ({ darkFooter, footerBackground }) => {
  return (
    <div
      css={[
        css`
          background: ${darkFooter ? footerBackground : "none"};
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            ${mq[2]} {
              justify-content: space-between;
            }
            ${mq[3]} {
              justify-content: center;
            }
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 1px;
              background: ${darkFooter ? "white" : "black"};
              opacity: ${darkFooter ? "0.25" : ".08"};
              display: ${darkFooter || footerBackground === undefined
                ? "block"
                : "none"};
            }
          `,
        ]}
      >
        <div
          css={[
            fontStyle.footer.item,
            css`
              color: ${darkFooter ? color.gray400 : color.black};
              font-weight: 500;
              font-size: 0.55em;
              letter-spacing: 0.05em;
              p {
                margin-bottom: 0;
              }
            `,
          ]}
        >
          <Link
            to={"https://bastiankroggel.com"}
            css={css`
              white-space: nowrap;
              display: flex;
              cursor: default;
            `}
          >
            <p>Crafted with</p>
            <div
              css={css`
                height: 1.5em;
                margin: 0 0.5em;
                svg {
                  height: 100%;
                  fill: ${darkFooter ? color.gray400 : color.black};
                }
              `}
            >
              <Heart />
            </div>
            <p>in Stuttgart</p>
          </Link>
        </div>
        <div
          css={css`
            display: none;
            ${mq[2]} {
              display: block;
            }
            ${mq[3]} {
              display: none;
            }
          `}
        >
          <SocialMediaElements inline={false} size={0.7} />
        </div>
      </div>
    </div>
  )
}

const Footer = ({ ...props }) => {
  const { darkFooter, footerBackground } = props

  let siteTitle

  if (Config.siteMetadata?.title && Config.siteMetadata.title < 10) {
    siteTitle = Config.siteMetadata.title
  } else if (
    Config.siteMetadata?.title &&
    GeneralData.short &&
    Config.siteMetadata.title > GeneralData.short
  ) {
    siteTitle = GeneralData.short
  } else {
    siteTitle = Config.siteMetadata.title
  }

  const getFooterData = () => {
    let completeNavigation = []

    if (NavData.header.navigation && NavData.header.navigation.length > 0) {
      const siteNavigation = {
        header: siteTitle,
        items: NavData.header.navigation,
      }
      completeNavigation.push(siteNavigation)
    }

    const footerNavigation = NavData.footer.navigation

    footerNavigation.forEach(e => {
      completeNavigation.push(e)
    })

    return completeNavigation
  }

  return (
    <footer>
      <section
        css={css`
          background: ${footerBackground};
          width: 100%;
          padding: 5rem 0;
        `}
      >
        <div
          css={[
            wrapper,
            css`
              ${mq[2]} {
                display: flex;
                justify-content: space-between;
              }
            `,
          ]}
        >
          <div
            css={css`
              min-width: 0;
              ${mq[2]} {
                margin-right: 5em;
              }
            `}
          >
            <LogoEl darkFooter={darkFooter} />
            <span
              css={css`
                ${mq[2]} {
                  display: none;
                }
              `}
            >
              {getFooterData().map((e, index) => (
                <Dropdown data={e} key={index} darkFooter={darkFooter} />
              ))}
              <Contact
                darkFooter={darkFooter}
                css={css`
                  padding: 4em 1em 1em;
                `}
              />
            </span>
            <CopyrightEL darkFooter={darkFooter} />
          </div>

          <div
            css={css`
              display: none;
              ${mq[2]} {
                display: flex;
                justify-content: flex-start;
              }
            `}
          >
            {getFooterData().map((e, index) => (
              <div
                key={index}
                css={css`
                  max-width: 10rem;
                  margin-right: 3em;
                `}
              >
                <p
                  css={[
                    fontStyle.footer.title,
                    css`
                      margin-bottom: 0.75em;
                      color: ${darkFooter ? "white" : "black"};
                    `,
                  ]}
                >
                  {e.header}
                </p>
                <InnerDropdown data={e.items} />
              </div>
            ))}
            <Contact
              darkFooter={darkFooter}
              css={css`
                display: none;
                ${mq[3]} {
                  display: block;
                }
              `}
            />
          </div>
        </div>
      </section>
      <Acknowledgement
        darkFooter={darkFooter}
        footerBackground={footerBackground}
      />
    </footer>
  )
}

export default Footer
